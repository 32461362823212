<template>
  <b-card>


    <!-- form -->
    <b-form>
      <b-row>
        <b-col sm="6">
          <b-form-group
            label="Name"
            label-for="account-name"
          >
            <b-form-input
              v-model="fullName"
              name="name"
              placeholder="Name"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="E-mail"
            label-for="account-e-mail"
          >
            <b-form-input
              v-model="mail"
              name="email"
              placeholder="Email"
            />

          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="Company"
            label-for="account-company"
          >
            <b-form-input
              v-model="company"
              name="company"
              placeholder="Company name"
            />
          </b-form-group>
        </b-col>

        <b-col sm="6">
          <b-form-group
            label="Country"
            label-for="account-country"
          >
            <b-form-input
              v-model="country"
              name="country"
              placeholder="Country"
            />
          </b-form-group>
        </b-col>

        <b-col sm="3">
          <b-form-group
            label="Zip"
            label-for="account-zip"
          >
            <b-form-input
              v-model="zip"
              name="zip"
              placeholder="Zip"
            />
          </b-form-group>
        </b-col>
        
        <b-col sm="3">
          <b-form-group
            label="City"
            label-for="account-city"
          >
            <b-form-input
              v-model="city"
              name="city"
              placeholder="City"
            />
          </b-form-group>
        </b-col>

        <b-col sm="6">
          <b-form-group
            label="Address"
            label-for="account-adress"
          >
            <b-form-input
              v-model="adress"
              name="address"
              placeholder="Address"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-button
            variant="primary"
            class="mt-2 mr-1 float-right"
          >
            Save
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BFormFile, BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BAlert, BCard, BCardText, BMedia, BMediaAside, BMediaBody, BLink, BImg,
} from 'bootstrap-vue'


export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
  },

  props: {

  },

  created() {
    this.myUser= JSON.parse(localStorage.getItem("loggedin_user"));
    this.adress= this.myUser.address;
    this.city= this.myUser.city;
    this.zip= this.myUser.zip;
    this.country= this.myUser.country;
    this.company= this.myUser.company;
    this.mail= this.myUser.mail;
    this.fullName= this.myUser.fullName;
  }, 

  data() {
    return {
      optionsLocal: {},
      profileFile: null,
      
    }
  },

  mounted() {

  },

  methods: {

  },

  setup() 
  {

  },
}
</script>
